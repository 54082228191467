import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Wrapper, WelcomeImg, WrapperImg, RedSpan } from "./styles";

import { Link as LinkUI } from "office-ui-fabric-react";
import { Text } from "office-ui-fabric-react/lib/Text";
import { Creators } from "~/store/ducks/home";
import {
  Page,
  BreadcrumbItems,
  DataTypes as DataTypesHome,
} from "~/store/ducks/home/types";
import { LoginType } from "~/store/ducks/login/types";
import { RootState } from "~/store/ducks";
import Colors from "~/assets/js/colors";

function Dashboard() {
  const { name } = useSelector<RootState, LoginType>(
    (state) => state.loginReducer.data
  );
  const { tarefasPendentes } = useSelector<RootState, DataTypesHome>(
    (state) => state.homeReducer
  );
  const history = useHistory();

  const items: BreadcrumbItems[] = [
    { text: "Página Inicial", isCurrentItem: true },
  ];

  const page: Page = {
    key: "home",
    pages: items,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(Creators.setCurrentPage(page));
    dispatch(Creators.getHome());
  }, [dispatch]);

  return (
    <Wrapper>
      <Text variant={"xxLarge"}>Olá, {name}</Text>
      <Text variant={"medium"}>
        Seja bem vindo ao Ciclo de Performance Marche.
      </Text>
      <br />
      {Number(tarefasPendentes) > 0 && (
        <>
          <Text>
            Você possui
            <LinkUI
              onClick={() => history.push("/workflow")}
              styles={{
                root: {
                  fontWeight: 600,
                  marginLeft: 5,
                  selectors: {
                    ":hover": { color: Colors.error },
                  },
                },
              }}
            >
              <RedSpan>{` ${tarefasPendentes} ${
                Number(tarefasPendentes) === 1
                  ? "tarefa pendente"
                  : "tarefas pendentes"
              }`}</RedSpan>
            </LinkUI>
            .
          </Text>
        </>
      )}
      {/* <Text variant={'medium'} styles={{ root: { width: 890 } }}>
        Aqui no St. Marche temos como propósito inovar constantemente a experiência do cliente e
        para isso acontecer precisamos de uma cultura de alta performance, conectando os objetivos da nossa Companhia
        diretamente aos sonhos e metas das nossas pessoas. Nosso Ciclo de Performance permite a valorização das pessoas
        e das boas práticas que sustentarão essa cultura de alta performance e agora, para fortalecer a gestão de resultados
        e dos nossos talentos, trouxemos para um sistema todo aquele processo que construímos juntos ao longo desses dois
        últimos anos.
      </Text> */}
      <WrapperImg>
        {/*
        <WelcomeImg src="/static/img/ciclo_2024_coletivo2.jpg" /> 

        cancelei a linha acima pois ela pediu para tirar a foto
        */}
         <WelcomeImg src="" />
      </WrapperImg>
      {/* <div className="ms-Grid" dir="ltr" style={{ marginTop: 15 }}>
        <div className="ms-Grid-row">
           <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="BIDashboard" text="Dashboard" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="StackedLineChart" text="Avaliações de Desempenho" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="AddFriend" text="Novo Funcionário" href={{ pathname: '/funcionarios', state: { isPanelOpen: true } }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="Calendar" text="Agenda" href={{ pathname: '/' }} />
          </div>
          <div className="ms-Grid-col paddingZero">
            <ButtonLink iconName="DocumentApproval" text="Aprovações Pendentes" onClick={() => { dispatch(CreatorsWorkflow.setCurrentPage('1')); history.push('/workflow') }} />
          </div> 
        </div>
      </div>*/}
    </Wrapper>
  );
}

export default Dashboard;
